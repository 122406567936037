/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { compose } from 'recompose';
import styledSystem from '@emotion/styled';
import { graphql } from 'gatsby';
import LayoutWithAuth from '../components/layoutWithAuth';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../components/Session';
import PasswordForgetForm from '../components/PasswordForget';
import PasswordChangeForm from '../components/PasswordChange';
import EmailChangeForm from '../components/EmailChange';
import DisplayNameChangeForm from '../components/DisplayNameChange';
import { Box, Flex } from '../style/basicStyle';

const AccountPageBase = () => (
  <AuthUserContext.Consumer>
    {(authUser) => {
      return (
        <>
          <Box fontSize={5} mx={[2, 3, 5]}>
            My Account
          </Box>
          <Box p={[2, 3, 5]}>
            <Box my={2}>
              <Box my={2}>
                Display Name:{' '}
                {authUser.providerData && authUser.providerData && authUser.providerData[0].displayName}
              </Box>
              <Box my={2}>
                Email:{' '}
                {authUser.email}
              </Box>
            </Box>
            <hr />
            <Box my={2}>
              <Box my={1}>
                <PasswordForgetForm />
              </Box>
              <Box my={1}>
                <PasswordChangeForm />
              </Box>
              <Box my={1}>
                <EmailChangeForm />
              </Box>
              <Box my={1}>
                <DisplayNameChangeForm />
              </Box>
            </Box>
          </Box>
        </>
      );
    }}
  </AuthUserContext.Consumer>
);

const condition = (authUser) => !!authUser;

const AccountPage = compose(withEmailVerification, withAuthorization(condition))(AccountPageBase);

export default ({ toggleNavDark, location }) => {
  return (
    <Box py={[5]}>
      <LayoutWithAuth navDark toggleNavDark={toggleNavDark}>
        <AccountPage />
      </LayoutWithAuth>
    </Box>
  );
};

export const pageQuery = graphql`
  query accountContentQuery {
    placeholderImage: imageSharp(original: { src: { regex: "/placeholderImage/" } }) {
      fluid(maxWidth: 1700, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
    heroImage: imageSharp(original: { src: { regex: "/accountHero/" } }) {
      fluid(maxWidth: 1500, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
    pageData: allPageDataJson(filter: { name: { eq: "account" } }) {
      edges {
        node {
          name
          title
          subtitle
          subtitle2
        }
      }
    }
  }
`;
